<!--
  金牌发行人列表
  auther：bashuang
-->
<template>
  <div class="issuer-list">
    <div class="banner">
      <div class="content">
        <div class="banner_top"></div>
        <ul>
          <li v-for="(item,index) in issuerList" :key="index" >
            <div class="details">
                <div class="above" @click="goDetail(item.issuerId)">
                  <div class="above_left">
                      <img :src="item.logo" alt="">
                  </div>
                  <div class="above_right">
                    <div class="tit">
                      {{item.issuerName}}
                    </div>
                    <div class="mini-icon" :id="'mini-icon-'+index">
                       <div class="left" v-for="(el,i) in item.tagList" :key="i"><span>{{el}}</span></div>
                    </div>
                    <div class='content2'>{{item.briefIntroduction}}</div>
                  </div>
                </div>
                <div class="latest_news" @click="toNewsDetail(item.newsId, item.newsTitle)">
                  <div class="news_left">
                    <img :src="require('@/assets/img/isuserList/zxzx.png')" alt="">
                  </div>
                  <div class="news_right">
                    {{item.newsTitle}}
                  </div>
                  <div class="time">
                    {{item.newsPublishTime | FILTER_FORMAT_TIME( "YYYY-MM-DD" )}}
                  </div>
                </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <my-foot @changePage="changePage" />
  </div>
</template>

<script>
import '../../assets/css/isuserList/index.scss'
import myFoot from '@/components/myFoot/index.vue'
import { getIssuerList } from '@/web/home'
export default {
  name: 'issuerList',
  data() {
    return {
      issuerList:[],
    }
  },
  components: {
    myFoot,
  },
  created() {
    getIssuerList({ pageNo: 1, pageSize: 20 }).then((res) => {
      this.issuerList = res.list
      console.log(this.issuerList)
      
    })
  },
  mounted() {},
  methods: {
    changePage(i) {
      this.$store.commit('changePage', i)
    },
    //跳转发行人
    toNewsDetail( id ){
      this.$router.push({path: '/detaillist', query: { newsId:  id } })    
    },
    //跳转发行人详情
    goDetail( issuerId ){
      this.$router.push({path: '/publisherDetail', query: { issuerId: issuerId } })
    }
  },
}
</script>


